import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome'
import AdminLogin from './components/admin/AdminLogin'
import BigScreenVideo from './components/scoreboard/BigScreenVideo';
import BigScreenAnswers from './components/scoreboard/Scoreboard';
import SetUpTickets from './components/admin/SetUpTickets';
import SetUpRacer from './components/admin/SetUpRacer';
import SetUpGame from './components/admin/SetUpGame';
import SetUpTeamVariables from './components/admin/SetUpTeamVariables';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpTicketEmail from './components/admin/SetUpTicketEmail';
import LoginVariables from './components/admin/SetUpLoginVariables';
import SetUpCampaignPicks from './components/admin/SetUpCampaignPicks';
import SetUpApi from './components/admin/SetUpApi';
import ApiDocumentation from './components/admin/ApiDocumentation';
import UserData from './components/admin/UserData';
import DunkinCodeUpload from './components/admin/DunkinCodeUpload';
import {app} from './base';
import isFastNet from 'isfastnet';
import './styles/css/NetworkActivity.css';
import UserInfo from "./components/admin/UserInfo";

function AuthenticatedAdminRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
              if(authenticated === true){
                return (
                  <Component {...props} {...rest} />
                )
              } else {
                return (
                  <Redirect to={{pathname: '/adminLogin', state: {from: props.location}}} />
                )
              }
              }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this)
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: true
        }
    }

    setCurrentUser(user) {
      if (user) {
          this.setState({
              currentUser: user,
              authenticated: true
          })
      } else {
          this.setState({
              currentUser: null,
              authenticated: false
          })
      }
    }

    componentDidMount() {
        this.removeAuthListener = app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    authenticated: true,
                    currentUser: user,
                    loading: false
                })
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false
                })
            }
        })
        isFastNet((value) => {
            let speed = value ? 'fast' : 'slow';
            if(speed !== this.state.speed){
                this.setState({
                    speed: speed
                });
                console.log('Internet is ' + speed);
            }
        })
    }

    componentWillUnmount() {
        this.removeAuthListener()
    }

    render() {
        let windowPath = window.location.pathname.toLowerCase();
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        return (
            <>
                <BrowserRouter>
                  <Switch>
                    <Route
                      exact
                      path="/bigscreenvideo"
                      render={(props) => {
                        return <BigScreenVideo {...props} />
                      }}
                    />
                    <Route
                          exact
                          path="/bigscreenanswers"
                          render={(props) => {
                              return <BigScreenAnswers {...props} />
                          }}
                    />
                    <Route
                        exact
                        path="/adminlogin"
                        user={this.state.currentUser}
                        render={(props) => {
                          return <AdminLogin setCurrentUser={this.setCurrentUser} {...props} />
                        }} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/admin"
                        authenticated={this.state.authenticated}
                        component={AdminHome} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupgame"
                        currentUser={this.state.currentUser}
                        authenticated={this.state.authenticated}
                        component={SetUpGame} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/longtermpicks"
                        authenticated={this.state.authenticated}
                        component={SetUpCampaignPicks} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupracers"
                        authenticated={this.state.authenticated}
                        component={SetUpRacer} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setuptickets"
                        authenticated={this.state.authenticated}
                        component={SetUpTickets} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupteamvariables"
                        authenticated={this.state.authenticated}
                        component={SetUpTeamVariables} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setuprulesandregs"
                        authenticated={this.state.authenticated}
                        component={SetUpRulesAndRegs} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupticketemail"
                        authenticated={this.state.authenticated}
                        component={SetUpTicketEmail} />

                    <AuthenticatedAdminRoute
                          exact
                          path="/setuploginvariables"
                          authenticated={this.state.authenticated}
                          component={LoginVariables} />

                      <AuthenticatedAdminRoute
                          exact
                          path="/apimanagement"
                          authenticated={this.state.authenticated}
                          component={SetUpApi} />

                      <AuthenticatedAdminRoute
                          exact
                          path="/apidocumentation"
                          authenticated={this.state.authenticated}
                          component={ApiDocumentation} />

                      <AuthenticatedAdminRoute
                          exact
                          path="/userdata"
                          authenticated={this.state.authenticated}
                          component={UserData} />

                      <AuthenticatedAdminRoute
                          exact
                          path="/userinfo"
                          authenticated={this.state.authenticated}
                          component={UserInfo}
                      />

                      <AuthenticatedAdminRoute
                          exact
                          currentUser={this.state.currentUser}
                          path="/dunkinCodeUpload"
                          authenticated={this.state.authenticated}
                          component={DunkinCodeUpload}
                      />

                    <Redirect to="/adminlogin"/>
                  </Switch>
                </BrowserRouter>
                {this.state.speed === "slow" && windowPath.indexOf("bigscreen") === -1 &&
                  <div className="slowNetworkAlert fade-in">
                      ⚠️ Slow Network Detected
                      <br/>
                      <span style={{fontSize: 10}}>Please check your internet</span>
                  </div>
                }
            </>
        )
    }
}

export default App;
