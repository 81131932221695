import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import InfiniteScroll from 'react-infinite-scroller';

class UserData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            responseSize: 50,
            usersList: [],
            page: 1,
        };
    }

    componentDidMount() {
        this.getUsersAccounts(this.state.responseSize, "");
    }

    async getUsersAccounts(numberToGet, search){
        // Set the attribute to order by
        let orderByAttribute = 'signUpTime';

        let queries = {
            limitToFirst: numberToGet,
            orderByChild: orderByAttribute
        };

        if(search){
            queries['startAt'] = search;
            queries['endAt'] = search + "\uf8ff";
        }

        // Query the database and capture the result
        const snapshot = await appDatabasePrimaryFunctions.ref('users').orderByChild(orderByAttribute)
            .startAt(queries.startAt ? queries.startAt : 0)
            .endAt(queries.endAt ? queries.endAt : "\uf8ff")
            .limitToFirst(queries.limitToFirst)
            .once('value');

        const array = [];
        snapshot.forEach(function (data){
            array.push(data.val())
        })
        const pageNumberAccurate = numberToGet / this.state.responseSize;
        this.setState({
            usersList: array,
            loading: false,
            page: pageNumberAccurate
        });
    }

    componentWillUnmount() {
    }

    async handleLoadMore(page){
        await this.getUsersAccounts(this.state.responseSize*page, document.getElementById('searchQuery').value)
    }

    render() {
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0}}>User Management</p>
                <p className="admin-subheader-text">Here you can go through your list of users</p>
                <div style={{paddingRight: 20}}>
                    <div style={{textAlign:"right"}}>
                        <input style={{minWidth:400}} type="text" id="searchQuery" name="search" placeholder="search" onChange={()=>{
                            const vm = this;
                            clearTimeout(this.timeoutId); // no-op if invalid id
                            this.timeoutId = setTimeout(async function(){
                                let input = document.getElementById('searchQuery').value;
                                await vm.getUsersAccounts(vm.state.responseSize, input);
                            }, 500);}
                        }/>
                        <div className="col-md-2 offset-10" style={{color:"black"}}>
                            <div className="row">
                                <div className="col-md-6">
                                    User Name
                                </div>
                                <div className="col-md-6">
                                    Email
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="radio" name="searchByUserName" id="searchByUserName" onChange={this.handleChange} value="userName" checked={this.state.searchByUserName === "userName"}/>
                                </div>
                                <div className="col-md-6">
                                    <input type="radio" name="searchByUserName" id="searchByUserName" onChange={this.handleChange} value="email" checked={this.state.searchByUserName === "email"}/>
                                </div>
                            </div>
                            {/*<label className="radio-inline" style={{marginRight:5}}>*/}
                            {/*    <input type="radio" name="searchByUserName" id="searchByUserName" onChange={this.handleChange} checked={this.state.searchByUserName}/>*/}
                            {/*        User Name*/}
                            {/*</label>*/}
                            {/*<label className="radio-inline">*/}
                            {/*    <input type="radio" name="searchByUserName" id="searchByUserName" onChange={this.handleChange} checked={!this.state.searchByUserName}/>*/}
                            {/*        Email*/}
                            {/*</label>*/}
                        </div>
                    </div>
                    <div style={{height:700, overflow:"auto"}}>
                        <InfiniteScroll
                            loadMore={(page) => this.handleLoadMore(page)}
                            hasMore={this.state.responseSize*this.state.page === this.state.usersList.length}
                            pageStart={0}
                            useWindow={false}
                        >
                            <table className="table table-striped" style={{color:'black', margin: 20}}>
                                <tbody>
                                <tr>
                                    <th>Email</th>
                                    <th>Uid</th>
                                    <th>Sign Up Time</th>
                                    <th>Actions</th>
                                </tr>
                                {
                                    this.state.usersList.map(function (item, i) {
                                        let link = `/userinfo?uid=${item.uid}`;
                                        return <tr key={i}>
                                            <td style={{fontFamily:'Open Sans'}}>{item.email || "None"}</td>
                                            <td style={{fontFamily:'Open Sans'}}>{item.uid}</td>
                                            <td style={{fontFamily:'Open Sans' }}>{item.signUpTime}</td>
                                            <td style={{fontFamily:'Open Sans' }}>
                                                <a href={link}>View</a>
                                            </td>
                                        </tr>
                                    }, this)
                                }
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
       </div>
      );
    }
}

export default UserData;
