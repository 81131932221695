import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import RichTextEditor from 'html-verify-react-rte';
import {PhotoshopPicker} from "react-color";
import {Modal} from "reactstrap";

class SetUpTicketEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            emailVariables: '',
            loading:true,
            showTabOneemailComponents: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        this.emailVariablesRef = base.listenTo(`emailVariables`, {
            context: this,
            state: 'emailVariables',
            then(data){
                let advanced = false;
                let sendStatsEmail = false;
                if(data && data.sender){
                    advanced = true;
                }
                if(data.statsEmailReceivers){
                    advanced = true;
                    sendStatsEmail = true;
                }
                console.log(data)
                this.setState({
                    loading: false,
                    advanced: advanced,
                    sendStatsEmail: sendStatsEmail,
                    emailVariables: data
                })
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.emailVariablesRef);
    }

    handleChange(event){
        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if(target.type === 'checkbox' && target.name !== "anonymizeData"){
            this.setState({[target.name]: value})
        } else {
            let emailVariablesCopy = this.state.emailVariables;
            emailVariablesCopy[event.target.name] = value
            this.setState({ emailVariables:  emailVariablesCopy});
        }
    }

    handleImageChange(event){
      const name_of_file = event.target.name;
      const target = this[name_of_file];
      const file_to_update =target.files[0];
      this.setState({loading:true})
      const vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              let emailVariablesCopy = vm.state.emailVariables;
              emailVariablesCopy[name_of_file] = res.imageUrl
              vm.setState({
                  emailVariables: emailVariablesCopy,
              }, () => {
                  vm[name_of_file].value = ""
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    openColorPicker(colorToChange){
        this.setState({
            colorPickerShow: true,
            colorToShow: this.state[colorToChange],
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        const emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy[this.state.colorToUpdate] = this.state.colorToShow;
        this.setState({
            emailVariables: emailVariablesCopy,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    handleRichTextChange = (name, text) => {
        let emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy[name] = text;
        this.setState({ emailVariables:  emailVariablesCopy});
    }

    handleSubmit(event) {
        event.preventDefault();
        const emailVariables = this.state.emailVariables;
        let sender = emailVariables.sender || "";
        let subjectLine = emailVariables.subjectLine || "";
        let helpText = emailVariables.helpText || "";
        let loserImageLink = emailVariables.loserImageLink || "";
        let loserSubjectLine = emailVariables.loserSubjectLine || "";
        let losingHelpText = emailVariables.losingHelpText || "";
        let losingDescription = emailVariables.losingDescription || "";
        let winningDescription = emailVariables.winningDescription || "";
        let backgroundImage = emailVariables.emailBackgroundImage || "";
        let losingEmailImage = emailVariables.losingEmailImage || "";
        let losingFooterImage = emailVariables.losingFooterImage || "";
        let losingHeaderImage = emailVariables.losingHeaderImage || "";
        let winningFooterImage = emailVariables.winningFooterImage || "";
        let winningHeaderImage = emailVariables.winningHeaderImage || "";
        let vendorName = emailVariables.vendorName || "";
        let buttonActionText = emailVariables.buttonActionText || "";
        let templateText = emailVariables.templateText || "";
        let dunkinEmailButtonText = emailVariables.dunkinEmailButtonText || "";
        let dunkinEmailButtonColor = emailVariables.dunkinEmailButtonColor || "";
        let dunkinEmailButtonTextColor = emailVariables.dunkinEmailButtonTextColor || "";
        let statsEmailReceivers = emailVariables.statsEmailReceivers || "";
        const anonymizeData = emailVariables.anonymizeData || false;
        statsEmailReceivers = statsEmailReceivers.trim();
        let sendStatsEmail = this.state.sendStatsEmail || false;
        if(!sendStatsEmail){
            statsEmailReceivers = "";
        }
         if(helpText === "<p><br></p>"){
            helpText = "";
        } else {
            helpText = helpText.toString('html')
        }
        if(losingHelpText === "<p><br></p>"){
            losingHelpText = "";
        } else {
            losingHelpText = losingHelpText.toString('html')
        }
        if(winningDescription === "<p><br></p>"){
            winningDescription = "";
        } else {
            winningDescription = winningDescription.toString('html')
        }
        if(losingDescription === "<p><br></p>"){
            losingDescription = "";
        } else {
            losingDescription = losingDescription.toString('html')
        }

        this.setState({loading:true})
        const updateRulesObject = {}
        updateRulesObject['helpText'] = helpText;
        updateRulesObject['subjectLine'] = subjectLine;
        updateRulesObject['emailBackgroundImage'] = backgroundImage;
        updateRulesObject['losingFooterImage'] = losingFooterImage;
        updateRulesObject['losingHeaderImage'] = losingHeaderImage;
        updateRulesObject['winningFooterImage'] = winningFooterImage;
        updateRulesObject['winningHeaderImage'] = winningHeaderImage;
        updateRulesObject['losingDescription'] = losingDescription;
        updateRulesObject['winningDescription'] = winningDescription;
        updateRulesObject['sender'] = sender;
        updateRulesObject['loserSubjectLine'] = loserSubjectLine;
        updateRulesObject['loserImageLink'] = loserImageLink;
        updateRulesObject['losingHelpText'] = losingHelpText;
        updateRulesObject['losingEmailImage'] = losingEmailImage;
        updateRulesObject['templateText'] = templateText;
        updateRulesObject['vendorName'] = vendorName;
        updateRulesObject['buttonActionText'] = buttonActionText;
        updateRulesObject['dunkinEmailButtonText'] = dunkinEmailButtonText;
        updateRulesObject['dunkinEmailButtonColor'] = dunkinEmailButtonColor;
        updateRulesObject['dunkinEmailButtonTextColor'] = dunkinEmailButtonTextColor;
        updateRulesObject['statsEmailReceivers'] = statsEmailReceivers;
        updateRulesObject['anonymizeData'] = anonymizeData;
      const vm = this;
      base.post("emailVariables/", {
        data: updateRulesObject,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Variables Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            // console.log(err)
          }
        }
      })
    }

    tripleToggleControl(element, whichToggle){
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        const tabThree = "showTabThree" + whichToggle;
        const firstElement = document.getElementById('showfirst' + whichToggle);
        const secondElement = document.getElementById('showsecond' + whichToggle);
        const thirdElement = document.getElementById('showthird' + whichToggle);
        if(element === "first"){
            firstElement.classList.add('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: true,
                [tabTwo]: false,
                [tabThree]: false,
            })
        }else if(element === "second"){
            firstElement.classList.remove('active');
            secondElement.classList.add('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: true,
                [tabThree]: false,
            })
        } else {
            firstElement.classList.remove('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.add('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: false,
                [tabThree]: true,
            })
        }
    }

    render() {
        let sender = this.state.emailVariables.sender || "";
        let subjectLine = this.state.emailVariables.subjectLine || "";
        let helpText = this.state.emailVariables.helpText || "";
        if(typeof helpText === 'string'){
            helpText = RichTextEditor.createValueFromString(helpText, 'html');
        } else if(!helpText) {
            helpText = RichTextEditor.createEmptyValue()
        }
        let emailBackgroundImage = this.state.emailVariables.emailBackgroundImage || "";
        let loserSubjectLine = this.state.emailVariables.loserSubjectLine || "";
        let losingDescription = this.state.emailVariables.losingDescription || "";
        let winningDescription = this.state.emailVariables.winningDescription || "";
        let templateText = this.state.emailVariables.templateText || "";
        let loserImageLink = this.state.emailVariables.loserImageLink || "";
        let losingEmailImage = this.state.emailVariables.losingEmailImage || "";
        let losingFooterImage = this.state.emailVariables.losingFooterImage || "";
        let losingHeaderImage = this.state.emailVariables.losingHeaderImage || "";
        let winningFooterImage = this.state.emailVariables.winningFooterImage || "";
        let winningHeaderImage = this.state.emailVariables.winningHeaderImage || "";
        let buttonActionText = this.state.emailVariables.buttonActionText || "";
        let vendorName = this.state.emailVariables.vendorName || "";
        let losingHelpText = this.state.emailVariables.losingHelpText || "";
        let statsEmailReceivers = this.state.emailVariables.statsEmailReceivers || "";
        const anonymizeData = this.state.emailVariables.anonymizeData || false;
        if(typeof losingHelpText === 'string'){
            losingHelpText = RichTextEditor.createValueFromString(losingHelpText, 'html');
        } else if(!losingHelpText) {
            losingHelpText = RichTextEditor.createEmptyValue()
        }
        if(typeof winningDescription === 'string'){
            winningDescription = RichTextEditor.createValueFromString(winningDescription, 'html');
        } else if(!winningDescription) {
            winningDescription = RichTextEditor.createEmptyValue()
        }
        if(typeof losingDescription === 'string'){
            losingDescription = RichTextEditor.createValueFromString(losingDescription, 'html');
        } else if(!losingDescription) {
            losingDescription = RichTextEditor.createEmptyValue()
        }
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        const isDunkin = process.env.REACT_APP_GAME_VERSION === "dunkin";
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";

        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <p className="admin-header-text" style={{marginBottom:0}}>{isMlbApp ? "Message Branding" : "Email Branding"}</p>
              <p className="admin-subheader-text">This is where you can edit the {isMlbApp ? "message" : "email"} sent to fans</p>
              <div className="container-out">
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-email-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:20}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      {isMlbApp && isDunkin &&
                          <>
                              <div className="form-group">
                                  <label htmlFor="showAdvancedSettings">Template Text</label>
                                  <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>This text should finish the phrase: You have an update from </p>
                                  <input id="templateText" name="templateText" className="form-control" value={templateText} onChange={this.handleChange} />
                              </div>
                              <div className="form-group">
                                  <label htmlFor="showAdvancedSettings">Button Action Text</label>
                                  <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>This text appears on the button that links to the prize</p>
                                  <input id="buttonActionText" name="buttonActionText" className="form-control" value={buttonActionText} placeholder="See My Prize" onChange={this.handleChange} />
                              </div>
                              <div className="form-group">
                                  <label htmlFor="showAdvancedSettings">Vendor Name</label>
                                  <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Shows Next To The Template Text</p>
                                  <input id="vendorName" name="vendorName" className="form-control" value={vendorName} placeholder="Dunkin" onChange={this.handleChange} />
                              </div>
                          </>
                      }
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.tripleToggleControl("first", "emailComponents")}>
                              <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirstemailComponents">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Winning</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.tripleToggleControl("second", "emailComponents")}>
                              <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showsecondemailComponents">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Losing</span>
                              </a>
                          </li>
                      </ul>
                      <div style={{display: this.state.showTabOneemailComponents ? 'block': 'none'}}>
                          <div className="row col-md-12">
                              <div className="form-group">
                                  <label htmlFor="subjectLine">{isMlbApp ? "Message": "Email"} Subject Line</label>
                                  <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the subject line that your fans will see when they receive their winning {isMlbApp ? "messages": "emails"}</p>
                                  <input id="subjectLine" name="subjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                              </div>
                          </div>
                          {!isMlbApp &&
                              <>
                                  <div className="row">
                                      <div className="form-group col-md-3">
                                          <label htmlFor="helpText">Help Text</label>
                                          <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is where you can tell fans where to contact you with issues.  (Example: Having issues? Email info@help.com for help.)</p>
                                          <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight:'100px'}} id="helpText" name="helpText" value={helpText} onChange={(text) => this.handleRichTextChange("helpText", text)} />
                                      </div>
                                      {!isDunkin &&
                                          <div className="form-group col-md-3" align="center">
                                              <img src={emailBackgroundImage} width="auto" height="100px" alt=""/>
                                              <br/>
                                              <label htmlFor="backgroundImage">Email Header Image<br/>(700px X 400px)</label>
                                              <div className="form-group">
                                                  <input style={{display:'none'}}  id="emailBackgroundImage" name="emailBackgroundImage" type="file" ref={input => {this.emailBackgroundImage = input; }} onChange={this.handleImageChange}/>
                                                  <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('emailBackgroundImage').click()} />
                                              </div>
                                          </div>
                                      }
                                  </div>
                                  {isDunkin &&
                                      <>
                                          <div className="row">
                                              <div className="form-group col-md-3">
                                                  <label htmlFor="winningDescription">Above Prize Image Text</label>
                                                  <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is text that shows up above the prize image</p>
                                                  <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight:'100px'}} id="winningDescription" name="winningDescription" value={winningDescription} onChange={(text) => this.handleRichTextChange("winningDescription", text)} />
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="form-group col-md-3" align="center">
                                                  <img src={winningHeaderImage} width="auto" height="100px" alt=""/>
                                                  <br/>
                                                  <label htmlFor="winningHeaderImage">Winning Header Image<br/>(Max Width 700px)</label>
                                                  <div className="form-group">
                                                      <input style={{display:'none'}}  id="winningHeaderImage" name="winningHeaderImage" type="file" ref={input => {this.winningHeaderImage = input; }} onChange={this.handleImageChange}/>
                                                      <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('winningHeaderImage').click()} />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="form-group col-md-3" align="center">
                                                  <img src={winningFooterImage} width="auto" height="100px" alt=""/>
                                                  <br/>
                                                  <label htmlFor="winningFooterImage">Winning Footer Image<br/>(Max Width 700px)</label>
                                                  <div className="form-group">
                                                      <input style={{display:'none'}}  id="winningFooterImage" name="winningFooterImage" type="file" ref={input => {this.winningFooterImage = input; }} onChange={this.handleImageChange}/>
                                                      <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('winningFooterImage').click()} />
                                                  </div>
                                              </div>
                                          </div>
                                      </>
                                  }
                                  {/*{isDunkin &&*/}
                                  {/*    <>*/}
                                  {/*        <div className="row col-md-12">*/}
                                  {/*            <div className="form-group">*/}
                                  {/*                <label htmlFor="dunkinEmailButtonText">Prize Link Button Text</label>*/}
                                  {/*                <input id="dunkinEmailButtonText" name="dunkinEmailButtonText" type="text" className="form-control" value={dunkinEmailButtonText} onChange={this.handleChange} placeholder="See My Prize"/>*/}
                                  {/*            </div>*/}
                                  {/*        </div>*/}
                                  {/*        <div className="row">*/}
                                  {/*            <div className="col-md-6">*/}
                                  {/*                <div className="form-inline">*/}
                                  {/*                    <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("dunkinEmailButtonTextColor")}/>*/}
                                  {/*                    <div style={{backgroundColor: dunkinEmailButtonTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>*/}
                                  {/*                    <label htmlFor="dunkinEmailButtonTextColor" style={{marginRight: 10}}>Button Text Color</label>*/}
                                  {/*                    <input style={{paddingRight:0}} id="dunkinEmailButtonTextColor" name="dunkinEmailButtonTextColor" type="text" className="form-control" value={dunkinEmailButtonTextColor} onChange={this.handleChange} placeholder="#000"/>*/}
                                  {/*                </div>*/}
                                  {/*            </div>*/}
                                  {/*            <div className="col-md-6">*/}
                                  {/*                <div className="form-inline">*/}
                                  {/*                    <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("dunkinEmailButtonColor")}/>*/}
                                  {/*                    <div style={{backgroundColor: dunkinEmailButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>*/}
                                  {/*                    <label htmlFor="dunkinEmailButtonColor" style={{marginRight: 10}}>Button Color</label>*/}
                                  {/*                    <input id="dunkinEmailButtonColor" name="dunkinEmailButtonColor" type="text" className="form-control" value={dunkinEmailButtonColor} onChange={this.handleChange} placeholder="#000"/>*/}
                                  {/*                </div>*/}
                                  {/*            </div>*/}
                                  {/*        </div>*/}
                                  {/*    </>*/}
                                  {/*}*/}
                              </>
                          }
                      </div>
                      <div style={{display: this.state.showTabTwoemailComponents ? 'block': 'none'}}>
                          <div className="row col-md-12">
                              <div className="form-group">
                                  <label htmlFor="loserSubjectLine">Losing Subject Line</label>
                                  <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the subject line that your fans will see when they receive their losing {isMlbApp ? "messages": "emails"}</p>
                                  <input id="loserSubjectLine" name="loserSubjectLine" className="form-control" value={loserSubjectLine} onChange={this.handleChange} />
                              </div>
                          </div>
                          {!isMlbApp && isDunkin &&
                              <div className="row col-md-12">
                                  <div className="form-group">
                                      <label htmlFor="loserImageLink">Losing Click Image Offer Link</label>
                                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Redirect Link When Losers Click Losing Image</p>
                                      <input id="loserImageLink" name="loserImageLink" className="form-control" value={loserImageLink} onChange={this.handleChange} />
                                  </div>
                              </div>
                          }
                          <div className="row">
                              {!isMlbApp &&
                                  <>
                                      <div className="form-group col-md-3">
                                          <label htmlFor="losingHelpText">Losing Help Text (Optional)</label>
                                          <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>This is where you can tell fans where to contact you with issues. (Example: Having issues? Email info@help.com for help.)</p>
                                          <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight: '100px'}} id="losingHelpText" name="losingHelpText" value={losingHelpText} onChange={(text) => this.handleRichTextChange("losingHelpText", text)}/>
                                      </div>
                                      <div className="form-group col-md-3" align="center">
                                          <img src={losingEmailImage} width="auto" height="100px" alt=""/>
                                          <br/>
                                          <label htmlFor="losingEmailImage">Losing Image<br/>(337px X 603px)</label>
                                          <div className="form-group">
                                              <input style={{display:'none'}}  id="losingEmailImage" name="losingEmailImage" type="file" ref={input => {this.losingEmailImage = input; }} onChange={this.handleImageChange}/>
                                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('losingEmailImage').click()} />
                                          </div>
                                      </div>
                                  </>
                              }
                          </div>
                          {isDunkin &&
                              <>
                                  <div className="row">
                                      <div className="form-group col-md-3">
                                          <label htmlFor="losingDescription">Above Prize Image Text</label>
                                          <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is text that shows up above the prize image</p>
                                          <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight:'100px'}} id="losingDescription" name="losingDescription" value={losingDescription} onChange={(text) => this.handleRichTextChange("losingDescription", text)} />
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="form-group col-md-3" align="center">
                                          <img src={losingHeaderImage} width="auto" height="100px" alt=""/>
                                          <br/>
                                          <label htmlFor="losingHeaderImage">Losing Header Image<br/>(Max Width 700px)</label>
                                          <div className="form-group">
                                              <input style={{display:'none'}}  id="losingHeaderImage" name="losingHeaderImage" type="file" ref={input => {this.losingHeaderImage = input; }} onChange={this.handleImageChange}/>
                                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('losingHeaderImage').click()} />
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="form-group col-md-3" align="center">
                                          <img src={losingFooterImage} width="auto" height="100px" alt=""/>
                                          <br/>
                                          <label htmlFor="losingFooterImage">Losing Footer Image<br/>(Max Width 700px)</label>
                                          <div className="form-group">
                                              <input style={{display:'none'}}  id="losingFooterImage" name="losingFooterImage" type="file" ref={input => {this.losingFooterImage = input; }} onChange={this.handleImageChange}/>
                                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('losingFooterImage').click()} />
                                          </div>
                                      </div>
                                  </div>
                              </>
                          }
                      </div>
                      <div className="form-group">
                          <label htmlFor="showAdvancedSettings">Advanced</label>
                          <br/>
                          <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                      </div>
                      {!isMlbApp && this.state.advanced &&
                          <div className="form-group">
                            <label htmlFor="sender">Email Sender</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the sender your fans will see when they receive their winning emails. BE AWARE: changing the sender could adversely impact delivery rates</p>
                            <input id="sender" name="sender" type="email" className="form-control" value={sender} onChange={this.handleChange} />
                          </div>
                      }
                      {/*{this.state.advanced &&*/}
                      {/*    <>*/}
                      {/*        <div className="form-group">*/}
                      {/*          <label htmlFor="sendStatsEmail">Send Stats Email</label>*/}
                      {/*          <br/>*/}
                      {/*          <input type="checkbox" checked={this.state.sendStatsEmail} id="sendStatsEmail" name="sendStatsEmail" onChange={this.handleChange}/>*/}
                      {/*        </div>*/}
                      {/*        {this.state.sendStatsEmail &&*/}
                      {/*            <>*/}
                      {/*                <div className="form-group" style={{marginLeft:20, marginRight: 20, marginTop: 10, marginBottom: 0, color: "black"}}>*/}
                      {/*                    <label htmlFor="statsEmailReceivers">Stats Email Receivers </label>*/}
                      {/*                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is where we will send your game stats recaps (for multiple emails, separate with commas)</p>*/}
                      {/*                    <input id="statsEmailReceivers" name="statsEmailReceivers" type="text" className="form-control" value={statsEmailReceivers} onChange={this.handleChange} />*/}
                      {/*                </div>*/}
                      {/*                <div className="form-group">*/}
                      {/*                    <label htmlFor="anonymizeData">Anonymize Data</label>*/}
                      {/*                    <br/>*/}
                      {/*                    <input type="checkbox" checked={anonymizeData} id="anonymizeData" name="anonymizeData" onChange={this.handleChange}/>*/}
                      {/*                </div>*/}
                      {/*            </>*/}
                      {/*        }*/}
                      {/*    </>*/}
                      {/*}*/}
                  </form>
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
              <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpTicketEmail
