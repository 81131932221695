import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import {app, base} from '../../base';
import logoWithTextImage from '../../styles/images/sqwad_white_logo_w_text.png';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tenantVariables: {}
        };
        this.logOut = this.logOut.bind(this)
    }

    componentDidMount() {
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    logOut() {
      app.auth().signOut().then((user, error) => {
        this.setState({ redirect: true })
      });
    }

    render() {
      const variables = this.state.tenantVariables || {};
      const homeLink = "/admin";
      const scratcherGameLink = "/setupgame";
      const ticketsLink = "/setuptickets";
      const racersLink = "/setupracers";
      const teamVariables = "/setupteamvariables";
      const rulesAndRegs = "/setuprulesandregs";
      const ticketEmail = "/setupticketemail";
      const loginVariables = "/setuploginvariables";
      const seasonLongLink = "/longtermpicks";
      const apiLink = "/apimanagement";
      const userSearch = "/userdata";
      const dunkinCodeUpload = "/dunkinCodeUpload";
      let doNotCollectEmail = variables.doNotCollectEmail;
      let isDunkin = process.env.REACT_APP_GAME_VERSION === "dunkin";
      const isPrediction = process.env.REACT_APP_RACE_TYPE === "prediction";
      let allowSeasonLongPicks = process.env.REACT_APP_ALLOW_CAMPAIGN_PICKS === "Yes" && variables.allowSeasonLongPicks;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const isApiEnabled =  process.env.REACT_APP_ENABLE_API === "true";
      const isLocalhost =  process.env.NODE_ENV === "development";
      let itemsText = "Racers";
      let changeIcon = false;
      if(process.env.REACT_APP_FIREBASE_PROJECT_ID.indexOf("pirateshatshuffle") !== -1){
        itemsText = "Player Choice"
      } else if(isPrediction) {
        itemsText = "Predictions"
      } else if(process.env.REACT_APP_FIREBASE_PROJECT_ID.indexOf("4th5th6th") !== -1){
        itemsText = "Innings"
        changeIcon = true
      }
      return (
          <div className="admin-sidebar">
            <div className="logo">
              <a href={homeLink}>
                <div className="logo-img"><img src={logoWithTextImage} height="30" alt=""/></div>
              </a>
            </div>
            <div className="nav-container">
              <ul className="nav">
                <li className="side-menu-items">
                  <a href={homeLink}>
                    <span className="fa fa-home pre_side_item"/>
                    <span className="">Dashboard</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={scratcherGameLink}>
                    <span className="fa fa-gamepad pre_side_item"/>
                    <span className="">Games</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={racersLink}>
                    {/*<span className="fa fa-car pre_side_item"/>*/}
                    <span className={changeIcon?`fa fa-clipboard pre_side_item`:`fa fa-car pre_side_item`}/>
                    <span className="">{itemsText}</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                {!isDunkin &&
                  <li className="">
                    <a href={ticketsLink}>
                      <span className="fa fa-trophy pre_side_item"/>
                      <span className="">Prizes</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                    </a>
                  </li>
                }
                <li className="">
                  <a href={teamVariables}>
                    <span className="fa fa-pencil-square-o pre_side_item"/>
                    <span className="">Game Branding</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={rulesAndRegs}>
                    <span className="fa fa-legal pre_side_item"/>
                    <span className="">Rules And Regs</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                {isMlbApp &&
                  <li className="">
                    <a href={ticketEmail}>
                      <span className="fa fa-envelope-open-o pre_side_item"/>
                      <span className="text">MLB APP Message</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                    </a>
                  </li>
                }
                <li className="" style={{display: doNotCollectEmail || isMlbApp ?"none":""}}>
                  <a href={ticketEmail}>
                    <span className="fa fa-envelope-open-o pre_side_item"/>
                    <span className="text">Email Branding</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="" style={{display: allowSeasonLongPicks?"":"none"}}>
                  <a href={seasonLongLink}>
                    <span className="fa fa-thumbs-o-up pre_side_item"/>
                    <span className="text">Campaign Picks</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={loginVariables}>
                    <span className="fa fa-users pre_side_item"/>
                    <span className="text">Fan Login</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                {isApiEnabled &&
                    <li className="">
                      <a href={apiLink}>
                        <span className="fa fa-bolt pre_side_item"/>
                        <span className="text">API</span>
                        <span className="fa fa-chevron-right after_fa_side"/>
                      </a>
                    </li>
                }
                {isLocalhost &&
                    <li className="">
                      <a href={userSearch}>
                        <span className="fa fa-bolt pre_side_item"/>
                        <span className="text">User Search (Beta)</span>
                        <span className="fa fa-chevron-right after_fa_side"/>
                      </a>
                    </li>
                }
                {isDunkin && isLocalhost &&
                    <li className="">
                      <a href={dunkinCodeUpload}>
                        <span className="fa fa-upload pre_side_item"/>
                        <span className="text">Dunkin Code Upload</span>
                        <span className="fa fa-chevron-right after_fa_side"/>
                      </a>
                    </li>
                }
                <div className="bottom-buttons">
                  <li className="" onClick={this.logOut}>
                    <a href={homeLink}>
                      <span className="fa fa-sign-out pre_side_item"/>
                      <span className="text">Logout</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>

        );
    }
}

export default SideMenu
