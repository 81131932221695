import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {randomBytes} from 'crypto';
import {base} from '../../base';
import '../../styles/css/AdminMain.css';

class SetUpApi extends Component {
    constructor(props) {
        super(props);
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`
        this.state = {
            apiKey: '',
            loading:true,
            mainApiUrl: url,
            startTimeVariable: 1
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        this.apiVariablesRef = base.syncState(`apiVariables`, {
            context: this,
            state: 'apiVariables',
            then(){
                this.setState({
                    loading: false
                })
            },
            onFailure(e){
                console.log(e);
                this.setState({
                    loading: false
                })
            }
        });
        this.idKeyRef = base.fetch(`id_key`, {
            context: this,
            then(data){
                this.setState({
                    id_key: data
                })
            }
        });
        // let startTime = 1653373078203;
        // const gamesListToCheck = await appDatabasePrimaryFunctions.ref('gamesList').orderByChild("timeStamp").once('value');
        // const gamesList = await appDatabasePrimaryFunctions.ref('gamesList').orderByChild("timeStamp").startAt(startTime).once('value');
        // const gameBefore = await appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').endAt(startTime).limitToLast(1).once('value')
        // gamesListToCheck.forEach(function (data){
        //     console.log(data.val().timeStamp)
        // })
        // console.log("------------------")
        // gameBefore.forEach(function (data){
        //     console.log(data.val().timeStamp)
        // })
        // console.log("------------------")
        // gamesList.forEach(function (data){
        //     console.log(data.val().timeStamp)
        // })
    }

    componentWillUnmount() {
        base.removeBinding(this.apiVariablesRef);
    }

    handleChange(evt){
        const apiVariablesCopy = this.state.apiVariables;
        const target = evt.target;
        const name = target.name;
        apiVariablesCopy[name] = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ apiVariables: apiVariablesCopy });
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    generateKey(size = 32, format = 'base64') {
        const buffer = randomBytes(size);
        const apiVariablesCopy = this.state.apiVariables;
        apiVariablesCopy['currentApiKey'] = buffer.toString(format);
        if(!apiVariablesCopy["gameName"]){
            apiVariablesCopy["gameName"] = process.env.REACT_APP_API_GAME_NAME;
        }
        this.setState({
            apiVariables: apiVariablesCopy
        })
    }

    deleteKey(){
        this.setState({
            apiVariables: null
        })
    }

    async testPingApi(){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/gameUsers`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/gameUsers`
        }
        // 1658766218126
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({apiKey: this.state.apiVariables.currentApiKey, startTime: 1662966000000, endTime: null, winner: false, page:1, eventNameToSearchFor: ""})
            body: JSON.stringify({apiKey: this.state.apiVariables.currentApiKey, startTime: 1660114800000, endTime: 1667113200000, winner: false, page:3, eventNameToSearchFor: ""})

        })
        console.log(await response.json())
    }

    render() {
        const apiVariables = this.state.apiVariables || {};
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "race-game-dev";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0}}>Api Management</p>
                <p className="admin-subheader-text">Manage your api key here that you can use to access data programmatically</p>
                {apiVariables.currentApiKey &&
                    <div style={{marginLeft: 20, color:"black"}}>
                        Current Key: <br/>{apiVariables.currentApiKey}
                        <button onClick={()=>this.generateKey()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginLeft: 20}}>
                            <span className="fa fa-refresh"/> Generate New Key
                        </button>
                        <button onClick={()=>this.deleteKey()} className="btn btn-primary btn-lg delete-button" id="submitButton" style={{marginLeft: 20}}>
                            <span className="fa fa-trash"/> Delete
                        </button>
                        {isDev &&
                            <button onClick={()=>this.testPingApi()} className="btn btn-primary btn-lg submit-button" id="submitButton" style={{marginLeft: 20}}>
                                <span className="fa fa-submit"/> Test Ping
                            </button>
                        }
                    </div>
                }
                {!apiVariables.currentApiKey &&
                    <div>
                        <button onClick={()=>this.generateKey()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px', marginLeft: 20}}>
                            <span className="fa fa-refresh"/> Generate Key
                        </button>
                    </div>
                }
                <div style={{display: "none"}}>
                    <div className="form-group">
                        <label htmlFor="gameName">Game Name:</label>
                        <input id="gameName" name="gameName" type="text" className="form-control" value={apiVariables.gameName} onChange={this.handleChange} placeholder={process.env.REACT_APP_API_GAME_NAME}/>
                    </div>
                </div>
                {isDev &&
                    <div className="form-group">
                        <label htmlFor="startTimeVariable">startTime:</label>
                        <input id="startTimeVariable" name="startTimeVariable" type="number" className="form-control" value={this.state.startTimeVariable} onChange={(startTimeVariable)=> this.setState({startTimeVariable: startTimeVariable.target.value})} placeholder="1"/>
                    </div>
                }
                <p className="admin-header-text" style={{marginBottom:0}}>Documentation</p>
                <p className="admin-subheader-text">How to use the games API</p>
                <a href="/apidocumentation" style={{marginLeft: 20}}>See the docs</a>
            </div>
         </div>
        );
    }
}

export default SetUpApi
