import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import InfiniteScroll from 'react-infinite-scroller';
import {getURLParameter} from "../utils/HelpfulFunction";

class UserInfo extends Component {
    constructor(props) {
        super(props);
        const uid = getURLParameter("uid");
        this.state = {
            uid: uid,
            loading: true
        };
    }

    componentDidMount() {
        this.getUserInfo();
    }

    async getUserInfo(){
        const uid = this.state.uid;
        const userInfoRef = await appDatabasePrimaryFunctions.ref(`users/${uid}`).once('value');
        const userInfo = userInfoRef.val() || {};
        this.setState({
            loading: false
        })
    }

    render() {
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0}}>User Info</p>
                <p className="admin-subheader-text">Here you can view your user</p>
                <div className="container" style={{color: "black", padding:20}}>
                    <div className="row" style={{margin: 20}}>
                        <div className="col" style={{textAlign:"center"}}>
                            <p style={{fontSize: 40}}>User Info</p>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="col">*/}
                    {/*        <p>*/}
                    {/*            Username: {userName}*/}
                    {/*        </p>*/}
                    {/*        <p>*/}
                    {/*            Email: {email || "None Found"}*/}
                    {/*        </p>*/}
                    {/*        <p>*/}
                    {/*            Signed Up: {signUpTime ? convertTimeStampToHumanReadable(signUpTime) : "None Detected"}*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*    {checkInEnabled &&*/}
                    {/*        <div className="col-4">*/}
                    {/*            <p>*/}
                    {/*                Check Ins: {checkInsCount}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    {(playerPickEnabled || checkInEnabled || badgesEnabled) &&*/}
                    {/*        <div className="col-4">*/}
                    {/*            <p>*/}
                    {/*                Total Points: {totalPoints}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    {(playerPickEnabled || checkInEnabled || badgesEnabled) &&*/}
                    {/*        <div className="col-4">*/}
                    {/*            <p>*/}
                    {/*                Available Points: {currentPoints}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*    {prizesEnabled &&*/}
                    {/*        <div className="col-4">*/}
                    {/*            <p>*/}
                    {/*                Prizes Redeemed: {rewardsRedeemedCount}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    {badgesEnabled &&*/}
                    {/*        <div className="col-4">*/}
                    {/*            <p>*/}
                    {/*                Badges Received: {numberOfBadges}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="advanced">Advanced</label>
                                <input value={this.state.advanced} className="form-check-input" id="advanced" name="advanced" type="checkbox" checked={this.state.advanced} onChange={this.handleChange} />
                            </div>
                        </div>
                    </div>
                    {this.state.advanced &&
                        <div className="row">
                            <div className="col" style={{textAlign: "center"}}>
                                <button className="btn btn-danger" onClick={()=>this.deleteUser()}>
                                    <span className="fa fa-trash pre_side_item"/> Permanently Delete User
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
       </div>
      );
    }
}

export default UserInfo;
