import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, base} from '../../base';
import {convertTimeStampToHumanReadable} from '../utils/HelpfulFunction';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';

class SetUpCampaignPicks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignPicksCount: 0,
            racerList: [],
            tenantVariables: {},
            showStats: true
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.racerListRef = base.bindToState(`racerList`, {
        context: this,
        state: 'racerList',
        asArray: true
      });
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      const vm = this;
      this.campaignPicksRef = appDatabasePrimaryFunctions.ref('campaignPicks').on('value', function(snapshot){
        vm.setState({
          loading: false,
          campaignPicksCount: snapshot.numChildren()
        })
      });
    }

    componentWillUnmount() {
      base.removeBinding(this.racerListRef);
      base.removeBinding(this.tenantVariablesRef);
      base.removeBinding(this.campaignPicksRef);
    }

    validUrl(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    }

    getSeasonLongPicks(limitToFirst=25, searchQuery){
        this.setState({
            loading: true
        })
        const vm = this;
        let queryString = appDatabasePrimaryFunctions.ref('campaignPicks')
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        if(searchQuery){
            let partialUid = searchQuery;
            if(!isMlbApp){
                partialUid = btoa(searchQuery);
            }
            queryString = queryString.orderByChild('uid').equalTo(partialUid)
        } else {
            queryString = queryString.orderByChild('timeStamp');
        }
        queryString = queryString.limitToFirst(limitToFirst);
        queryString.once('value', async function (queryStringSnapshot) {
            const campaignPicksArray = [];
            queryStringSnapshot.forEach(function(data){
                campaignPicksArray.push(data.val())
            })
            for(const i in campaignPicksArray){
                const campaignPick = campaignPicksArray[i];
                let found = false;
                for(const alreadySavedIndex in vm.state.campaignLongPicks){
                    const existingCampaignPick = vm.state.campaignLongPicks[alreadySavedIndex];
                    if(existingCampaignPick.uid === campaignPick.uid){
                        found = existingCampaignPick;
                    }
                }
                if(found) {
                    campaignPicksArray[i]['email'] = found.email || found.uid || "";
                } else {
                    campaignPicksArray[i]['email'] = await appDatabasePrimaryFunctions.ref(`users/${campaignPicksArray[i].uid}/email`).once('value').then(function(snapshot){
                        return snapshot.val() || "";
                    })
                }
            }
            vm.setState({
                campaignLongPicks: campaignPicksArray,
                loading: false
            })
        });
    }

    resetSeasonLongPicks(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will remove all the user CAMPAIGN LONG picks!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES',
        }).then(async (result)=>{
          if(result.value){
            await base.remove("campaignPicks");
            this.setState({
                campaignLongPicks: []
            })
            swal({
              title: 'Picks Reset',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
          }
      })
    }

    handleChange (evt) {
      const target = evt.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    async switchStatsPrizes(buttonClicked){
        if(buttonClicked === "showStats"){
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showStats').classList.add('active');
            this.setState({
                showStats:true,
                showPrizes:false,
                campaignLongPicks: []
            })
        }else if(buttonClicked === "showPrizes"){
            this.getSeasonLongPicks();
            document.getElementById('showPrizes').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            this.setState({
                showStats:false,
                showPrizes:true
            })
        }
    }

    render() {
      const tenantVariables = this.state.tenantVariables || {};
      let racerList = this.state.racerList || [];
      let campaignLongPicks = this.state.campaignLongPicks || [];
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-3">
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetSeasonLongPicks()} style={{marginTop:'7px', float:'right'}}>Reset Picks</a>
                </div>
              </div>
            </div>
            <div>
              <div className="row justify-content-center">
                <h3 style={{color:'black', margin:'15px'}}>Season Long Picks</h3>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-3" style={{border: "solid 1px black", margin: 5}}>
                  <h4 style={{color:'black'}}>Racers</h4>
                  {
                    racerList.map(function(item, index){
                      return (
                          <div key={index} style={{textAlign:'left', color:'black'}}>
                            <p>Racer: {item.racerName}{item.winner ? ", WINNER": ""}</p>
                          </div>
                      )
                    })
                  }
                </div>
                <div className="col-md-3" style={{border: "solid 1px black", margin: 5}}>
                  <h4 style={{color:'black'}}>Rewards</h4>
                    <div style={{textAlign:'left', color:'black'}}>
                      <p>Rewards will be given out when game rewards are distributed if a user has the appropriate racer picked</p>
                    </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Stats</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showPrizes")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Picks</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                    <div className="row" style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Participants</p>
                        <div className="form-group" style={{marginLeft: 'auto', marginBottom: 'auto', marginTop: 'auto'}}>
                            <input id="searchQuery" name="searchQuery" className="form-control" placeholder={isMlbApp ? "search EXACT uid" : "search EXACT email"} type="text" onChange={()=>{
                                const vm = this;
                                clearTimeout(this.timeoutId); // no-op if invalid id
                                this.timeoutId = setTimeout(function(){
                                    let input = document.getElementById('searchQuery').value;
                                    vm.getSeasonLongPicks(25,input);
                                }, 500);
                            }}/>
                        </div>
                    </div>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>{tenantVariables.doNotCollectEmail || isMlbApp ? "UID" : "Email"}</th>
                        <th>Pick</th>
                        <th>Time Stamp</th>
                      </tr>
                      {
                        campaignLongPicks.map(function(item,i){
                            const timeStamp = convertTimeStampToHumanReadable(item.timeStamp);
                            let pickName = "";
                            for(const i in racerList){
                                const racer = racerList[i];
                                if((racer.id || racer.key) ===item.pickid){
                                    pickName = racer.racerName
                                }
                            }
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{tenantVariables.doNotCollectEmail || isMlbApp ? item.uid : item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{pickName}</td>
                              <td style={{fontFamily:'Open Sans' }}>{timeStamp}</td>
                            </tr>
                        }, this)
                      }
                      {this.state.campaignPicksCount > campaignLongPicks.length && !document.getElementById('searchQuery').value &&
                          <button style={{marginTop: 10}} className="btn btn-primary" onClick={()=>this.getSeasonLongPicks(campaignLongPicks.length + 25)}>
                              More
                          </button>
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <div className="admin-grid-container three-columns" style={{marginTop:20}}>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Campaign Players</p>
                                <footer className="value-text">{this.state.campaignPicksCount}</footer>
                            </blockquote>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       </div>
      );
    }
}

export default SetUpCampaignPicks;
