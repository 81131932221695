import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import {getURLParameter, detectHowManyCodesEntered} from "../utils/HelpfulFunction";

let racerList = [
    {racerName: "biggie_bagel"},
    {racerName: "dashing_donut"},
    {racerName: "hot_coffee"},
    {racerName: "ice_coffee"}
]

class DunkinCodeUpload extends Component {
    constructor(props) {
        super(props);
        const uid = getURLParameter("uid");
        this.state = {
            uid: uid,
            loading: false,
            dunkin_donut_racers: racerList,
            remainingCodes: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt){
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
        if(evt.target.name === "racerName"){
            this.countHowManyCodesRemain(value);
        }
    }

    async countHowManyCodesRemain(racerName){
        const racerCodes = await appDatabasePrimaryFunctions.ref('prizesToGiveAway/' + racerName).orderByChild('givenTo').equalTo('none').once('value');
        this.setState({remainingCodes: racerCodes.numChildren()})
    }

    async submitCodes(){
        const codesArray = this.state.codesArray;
        const racerName = this.state.racerName;
        let splitCodes = detectHowManyCodesEntered(codesArray);
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/uploadDunkinCodes`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/uploadDunkinCodes`
        }
        this.setState({loading:true})
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({racerName: racerName, codesArray: splitCodes})
        })
        if(response) {
            const value = await Promise.resolve(response.json());
            alert(value.message);
        }
        this.setState({loading:false})
    }

    render() {
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0}}>Dunkin Code Upload</p>
                <p className="admin-subheader-text">Here you can view your codes but they still need images to be uploaded elsewhere</p>
                <div className="container" style={{color: "black", padding:20}}>
                    <div className="row">
                        Count of codes remaining: {this.state.remainingCodes}
                    </div>
                    <div className="row">
                        <select className="form-control" name="racerName" id="racerName" value={this.state.racerName} onChange={this.handleChange}>
                            <option/>
                            {
                                this.state.dunkin_donut_racers.map(function (item, index) {
                                    return (
                                        <option value={item.racerName} key={index}>{item.racerName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="row">
                        <div className="form-group row mb-3">
                            <label htmlFor="codesArray" className="col-form-label">Enter Codes (1234,1238,1230)</label>
                            <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                        </div>
                    </div>
                    <div className="row">
                        <button className="btn btn-primary" type="button" onClick={() => this.submitCodes()}>Submit Codes</button>
                    </div>
                </div>
            </div>
       </div>
      );
    }
}

export default DunkinCodeUpload;
